<template>
  <CRow>
    <CCol xs="12" md="6">
      <CInput placeholder="Ingrese el texto a buscar" v-model="search" @keyup="onTyping">
        <template #prepend-content
          ><font-awesome-icon icon="search"
        /></template>
      </CInput>
    </CCol>
    <CCol xs="12" md="6">
      <CButton
        class="float-right ml-2"
        color="info"
        @click="() => $router.push('/users/import')"
        v-if="isadmin"
      >
        Importar usuarios
      </CButton>
      <CButton
        class="float-right"
        color="success"
        @click="() => $router.push('/users/0')"
      >
        Nuevo usuario
      </CButton>
    </CCol>

    <CCol col="12">
      <CCard>
        <CCardHeader> Users </CCardHeader>

        <CCardBody>
          <div class="text-center">
            <CSpinner v-if="loading" variant="grow" size="lg" />
          </div>

          <CDataTable
            v-if="!loading"
            hover
            striped
            sorter
            :items="items"
            :fields="fields"
            clickable-rows
            @row-clicked="rowClicked"
          >
            <template #status="data">
              <td>
                <CBadge :color="getBadge(data.item.isactive)">
                  {{ data.item.isactive?"Activo":"Inactivo" }}
                </CBadge>
              </td>
            </template>
            <template #created_at="data">
              <td>
                {{ data.item.created_at | dateFormat }}
              </td>
            </template>
          </CDataTable>

          <CPagination
            v-if="!loading"
            :activePage.sync="page"
            :pages="lastPage"
            size="sm"
            align="center"
            @update:activePage="pageChange"
          />
        </CCardBody>
      </CCard>
    </CCol>
  </CRow>
</template>

<script>
// import UserResource from "@/api/user";
// const userResource = new UserResource();

import Resource from "@/api/resource";
import moment from "moment";

const resource = new Resource("user");

export default {
  name: "Users",
  data() {
    return {
      items: [],
      loading: false,
      fields: [
        {
          key: "name",
          label: "Name",
          _classes: "font-weight-bold",
          sorter: true,
          filter: true,
        },
        {
          key: "email",
          label: "Correo",
          _classes: "font-weight-bold",
          sorter: true,
          filter: true,
        },
        { key: "created_at" },
        { key: "role", sorter: true, filter: true },
        { key: "status", sorter: true, filter: true },
      ],
      role: ["Administrador", "Vendedor", "Distribuidor", "Financiero"],
      status: ["Activo", "Inactivo"],
      page: 1,
      perPage: 30,
      lastPage: 1,
      search: null,
      delayTimer: null,
    };
  },
  filters: {
    dateFormat: function (value) {
      return value ? moment(value).format("YYYY-MM-DD HH:mm") : "";
    },
  },
  computed: {
    isadmin() {
      return (
        this.$store.state.user.role == "Administrador"
      );
    },
  },
  mounted() {
    this.page = this.$route.query.page ? parseInt(this.$route.query.page) : 1;
    this.getItems();
  },
  methods: {
    async getItems() {
      this.loading = true;
      try {
        const response = await resource.list({
          page: this.page,
          perPage: this.perPage,
          search: this.search,
        });
        if (response.status == 200) {
          this.items = response.data.data;
          this.lastPage = response.data.last_page;
        } else {
          console.log(response);
        }
      } catch (error) {
        console.log(error);
      } finally {
        this.loading = false;
      }
    },

    getBadge(status) {
      switch (status) {
        case "Activo":
          return "success";
        case "Inactivo":
          return "secondary";
        case "Pending":
          return "warning";
        case "Banned":
          return "danger";
        case true:
          return "success";
        case false:
          return "secondary";
        default:
          "primary";
      }
    },

    rowClicked(item, index) {
      this.$router.push({ path: `users/${item.id}` });
    },

    async pageChange(val) {
      this.$router.push({ query: { page: val } });
      await this.getItems();
      this.page = val;
    },

    onTyping() {
      clearTimeout(this.delayTimer);
      this.delayTimer = setTimeout(async () => {
        this.getItems();
      }, 500);
    },
  },
};
</script>
